@import "../../../../../_default/Skins/2sic/src/scss/_variables.scss";
@import "../../../../../_default/Skins/2sic/node_modules/bootstrap/scss/mixins/_breakpoints.scss";

.nav.nav-pills.reference-nav {
  .nav-item {
    
    .nav-link {
      border: 1px solid $gray-600;
      font-size: 13px;
      margin-right: 5px;
      margin-bottom:5px;
      &:before {
        content:"";
        background:transparent;
        height:0px;
      }
      &:hover {
        &:before {
          content:"";
          background:transparent;
          height:0px;
        }
        &:after {
          content:"";
          background:transparent;
          height:0px;
        }
      }
      &.clear-filter {
        border: 0px solid $gray-600;
        display: flex;
        svg {
          width: 8px;
          margin-right: 5px;
        }
        &:hover {
          svg {
           fill: white;
          }
        }
      }
    }
  }
}

.app-reference-list-item.hidden {
  display: none !important;
}

.clear-filter {
  display: none;
}

.app-reference-list-item {
  > img {
    filter: grayscale(1);
    transform:scale(1);
    transition: transform 0.3s ease-in-out ,filter 0.2s ease-in-out;
  }
  &:hover,
  &:focus {
    cursor: pointer;

    > img {
      filter: grayscale(0);
      transform:scale(1.1);
    }
  }
}
.refs {
  img.screenshot {
    &:first-child {
      width:100%;
    }
  }
  .btn {
    outline: none!important;
  }
  .bg-container {
    background-size:cover;
    background-repeat: no-repeat;
  }
}
.references {
  max-width: 80vw;
  max-height: 80vh;
  overflow-y: auto;
  @include media-breakpoint-up(lg){
    max-width:50vw;
  }
}


.fancybox__container p {
  pointer-events: none!important;
}
